<template>
  <b-card>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="UsersIcon"/>
          <span>Genel Bilgiler</span>
        </template>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
            <b-row class="mt-1">
              <b-col cols="12" md="9" lg="9">
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="İsim" rules="required">
                      <b-form-group label="* İsim">
                        <b-form-input trim placeholder="İsim" v-model="dataInfo.firstName" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Soyisim" rules="required">
                      <b-form-group label="* Soyisim">
                        <b-form-input trim placeholder="Soyisim" v-model="dataInfo.lastName" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Şirket Telefon">
                      <b-form-group label="Şirket Telefon">
                        <b-form-input trim placeholder="Şirket Telefon" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Kişisel Telefon">
                      <b-form-group label="Kişisel Telefon">
                        <b-form-input trim placeholder="Kişisel Telefon" v-model="dataInfo.phoneNumber2" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="E-Posta" rules="email">
                      <b-form-group label="E-Posta">
                        <b-form-input trim placeholder="E-Posta" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Kullanıcı Adı">
                      <b-form-group label="Kullanıcı Adı">
                        <b-form-input trim placeholder="Kullanıcı Adı" v-model="dataInfo.userName" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Kullanıcı Rolü" rules="required">
                      <b-form-group label="* Kullanıcı Rolü" :state="getValidationState(validationContext)">
                        <v-select multiple v-model="dataInfo.roles" :options="roleOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Durum" rules="required">
                      <b-form-group label="* Durum" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <div class="mt-1 d-flex justify-content-center align-content-end">
                  <b-avatar
                      ref="previewEl"
                      :src="(dataInfo.profilePictureBase64 ? dataInfo.profilePictureBase64 : getApiFile(dataInfo.profilePicture))"
                      :text="avatarText(dataInfo.firstName + ' ' + dataInfo.lastName)"
                      button
                      variant="primary"
                      size="70px"
                      @click="$refs.refInputEl.click()"/>
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer">
                </div>
              </b-col>
            </b-row>
            <action-buttons :back-route="'system-user-list'"/>
          </b-form>
        </validation-observer>
      </b-tab>
      <DeviceList></DeviceList>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/user/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils"
import roleModule from "@/views/system/role/store"
import DeviceList from "@/views/system/user/device/List.vue";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    DeviceList,
    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ROLE_MODULE_NAME = 'store-role'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ROLE_MODULE_NAME, roleModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ROLE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumber2: '',
      email: '',
      userName: '',
      profilePicture: '',
      profilePictureBase64: null,
      isActive: true,
      roles: [],
    })

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.profilePictureBase64 = base64
    })

    const onSubmit = () => {
      if (!$themeConfig.app.appIsDemo) {
        if ((dataInfo.value.phoneNumber === null || dataInfo.value.phoneNumber === '') && (dataInfo.value.phoneNumber2 === null || dataInfo.value.phoneNumber2 === '')) {
          toastMessage(toast, 'danger', 'Şirket telefonu veya Kişisel telefon bilgisi boş geçilemez!')
          return
        }

        busy.value = true
        store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
          toastMessage(toast, 'success', response.data.message)
          router.push({name: 'system-user-list'})
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const roleOptions = ref([])
    busy.value = true
    store.dispatch('store-role/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          roleOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'system-user-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      dataInfo,
      previewEl,
      refInputEl,
      refFormObserver,
      roleOptions,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
